@import '~@angular/material/prebuilt-themes/deeppurple-amber.css';
@import './../../assets/styles/typography.less';
@import '~quill/dist/quill.snow.css';
@import '/node_modules/flag-icons/css/flag-icons.min.css';

@font-face {
  font-family: bebas;
  src: url('./../../assets/fonts/BebasNeue.woff') format('woff');
}

@font-face {
  font-family: elektra;
  src: url('./../../assets/fonts/ElektraTextPro.woff') format('woff');
}

@font-face {
  font-family: elektra bold;
  src: url('./../../assets/fonts/ElektraTextPro-Bold.woff') format('woff');
}

@font-face {
  font-family: orbitron;
  src: url('./../../assets/fonts/Orbitron-Regular.woff') format('woff');
}

* {
  box-sizing: border-box;
  font-family: elektra;
}

body {
  font-size: 14px;
  color: var(--base-font-color);
}

.comment-inner img {
  vertical-align: middle;
  max-height: 24px;
  max-width: 24px;
  margin: 0px 3px;
}

button {
  outline: none;
}

.shadowed {
  box-shadow: 1px 1px 5px 2px rgba(0, 0, 0, 0.4);
}

.shadowed-main-block {
  .shadowed();
  padding: 15px;
  background: var(--base-background);

  & + & {
    margin-top: 25px;
  }
}

a {
  color: var(--base-link-blue-color);
  text-decoration: none;

  &:hover {
    color: #23527c;
    text-decoration: underline;
  }
}

b {
  font-weight: 700;
}

.blue-link {
  color: var(--alternative-link-blue-color);
  opacity: 0.8;

  &:hover {
    opacity: 1;
  }
}

.place-icon {
  width: 18px;
  height: 18px;
}

.mat-mdc-dialog-surface {
  padding: 20px 24px 16px !important;
  border: 4px solid var(--comments-border-color);
  background: var(--base-background);
  color: var(--base-font-color);
  border-radius: 4px;
}

mat-divider {
  border-top-color: var(--divider-border-color) !important;
}

.mat-mdc-raised-button {
  &.mat-accent {
    background-color: var(--accent-button-color) !important;
  }
}

.mat-expansion-panel {
  background: var(--base-background) !important;
  box-shadow: none !important;
}

.mat-expansion-panel-body {
  padding-left: 15px !important;
  padding-right: 15px !important;
}

.lowercase {
  text-transform: lowercase;
}

input {
  outline: none;
}

.flex {
  display: flex;
}

.bebas {
  font-family: bebas;
}
html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: Roboto, 'Helvetica Neue', sans-serif;
}

html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: Roboto, 'Helvetica Neue', sans-serif;
}

mat-slide-toggle {
  .mdc-switch__handle::before {
    background: none !important;
  }

  .mdc-switch__icons {
    display: none;
  }

  &.EN {
    .mdc-switch__handle::after {
      background: url('../../assets/images/icons/langs/gb.png') !important;
      background-size: 100% !important;
      border: 0;
      transform: scale(95%);
    }
  }

  &.RU {
    .mdc-switch__handle::after {
      background: url('../../assets/images/icons/langs/ru.png') !important;
      background-size: 100% !important;
      border: 0;
      transform: scale(95%);
    }
  }

  &.light {
    .mdc-switch__handle::after {
      background: url('../../assets/images/icons/langs/light.png') !important;
      background-size: 100% !important;
      border: 0;
    }
  }

  &.dark {
    .mdc-switch__handle::after {
      background: url('../../assets/images/icons/langs/dark.png') !important;
      background-size: 100% !important;
      border: 0;
    }
  }
}

.mdc-switch__track::before,
.mdc-switch__track::after {
  background: var(--toggle-background-color) !important;
}

.mdc-switch__shadow {
  display: none;
}

.mat-expansion-indicator::after {
  color: var(--base-font-color) !important;
}

.mat-mdc-tab-link {
  flex-grow: 0 !important;
}

.mdc-tab {
  .mdc-tab__text-label {
    user-select: none;
    font-family: elektra;
    color: var(--navigation-color) !important;
    opacity: 0.6;
    letter-spacing: normal;
  }

  &--active {
    .mdc-tab__text-label {
      opacity: 1 !important;
    }
  }
}

.mat-expansion-panel-content {
  letter-spacing: normal !important;
}

.mdc-button {
  letter-spacing: normal;
}

.mat-mdc-dialog-container .mdc-dialog__surface {
  background-color: var(--dialog-container-color) !important;
}

.mat-mdc-raised-button:disabled {
  color: light-dark(rgba(16, 16, 16, 0.3), rgba(255, 255, 255, 0.3)) !important;
}

.smile {
  background: url('/assets/images/smiles/smiles2.png');
  width: 32px;
  height: 32px;
  transform: scale(0.75);
  transform-origin: 0 0;

  &.big {
    transform: none;
  }
}

.flag {
  background: url('/assets/images/flags/sprite.png');
  height: 13px;
  width: 18px;
}
