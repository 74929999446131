.h1-block-header {
  margin: 0;
  color: var(--base-header-blue-color);
  font-family: bebas;
  font-size: 31px;
  letter-spacing: 1.5px;
  margin-bottom: 15px;
  font-weight: normal;
  line-height: initial;
}

.h1-block-header span {
  font-family: bebas;
}

.side-block-header {
  margin: 0;
  color: var(--base-header-blue-color);
  font-family: bebas;
  font-size: 24px;
  letter-spacing: 2px;
  font-weight: normal;
  line-height: initial;
}

.side-block-header span {
  font-family: bebas;
}

.italic-console-command {
  font-style: italic;
  background: var(--rules-hightlight-color);
}

.bold-15 {
  font-weight: bold;
  font-size: 15px;
}
